import React, { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector, batch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';

import { getRequest, postRequest } from 'utils/api';
import { setCart } from 'features/cartSlice';
import useAgencyRedirect from 'hooks/useAgencyRedirect';
import {
  getTokenFromResponse,
  identifyFullStoryUser,
  resetCrispSession,
  setCrispUser,
} from 'utils/helpers';
import {
  deleteAccessToken,
  getAccessToken,
  saveAccessToken,
  getUuid,
  generateUuid,
  setReferralCode,
  getCurrentUserLS,
  setCurrentUserLS,
  getMasquerading,
} from 'utils/localStorage';
import {
  setNetworks,
  setRoleFilter,
  setUnseenNotificationsCount,
  setWebNotifications,
} from 'features/utilsSlice';
import {
  setCurrentUser,
  setCurrentUserChecked,
  setReadyToCheck,
  signIn,
  signOut,
} from 'features/authSlice';
import { setFollowingIds } from 'features/profilesSlice';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

const Root = ({ children }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const currentUser = useSelector(state => state.auth.currentUser);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const readyToCheck = useSelector(state => state.auth.readyToCheck);
  const currentUserChecked = useSelector(state => state.auth.currentUserChecked);
  const webNotifications = useSelector(state => state.utils.webNotifications);
  const ref = router.query.ref;
  const isProfilePage = router.pathname === '/u/[username]';

  useAgencyRedirect();

  const keyUpHandler = useCallback(e => {
    if (e.code === 'Enter' && e.target.tagName === 'INPUT') e.target.blur();
  }, []);

  useEffect(() => {
    const token = getAccessToken();
    const uuid = getUuid();

    if (!token && !uuid && isProfilePage) {
      generateUuid();
    }
  }, [isProfilePage]);

  useEffect(() => {
    const currentUser = getCurrentUserLS();

    if (!isEmpty(currentUser)) {
      dispatch(setCurrentUser(currentUser));
      dispatch(
        setRoleFilter(currentUser.primary_interest === 'buy' ? 'advertiser' : currentUser.role)
      );
    }

    dispatch(setReadyToCheck(true));
  }, [dispatch]);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const token = getAccessToken();
        const expiry = jwtDecode(token)['exp'];
        const user = await getRequest({ endpoint: 'users/current' });

        // When the admin is masquerading as another user, don't refresh the token
        if ((expiry - Date.now() / 1000) / 86400 < 29 && !getMasquerading()) {
          const response = await postRequest({
            endpoint: 'users/refresh_token',
            resolveResponse: true,
          });

          const token = getTokenFromResponse(response);

          if (token) saveAccessToken(token);
        }

        if (loggedIn) {
          dispatch(signIn(user));
        } else {
          dispatch(signIn({ ...user, initial: true }));
          dispatch(setRoleFilter(user.role));
        }

        setCurrentUserLS(user);

        setCrispUser(user);
        identifyFullStoryUser(user);
      } catch {
        deleteAccessToken();

        batch(() => {
          dispatch(signOut());
          setCurrentUserLS({});
          dispatch(setCurrentUserChecked(true));
        });

        resetCrispSession();
      }
    };

    if (!currentUserChecked && readyToCheck) checkUser();
  }, [dispatch, loggedIn, readyToCheck, currentUserChecked]);

  useEffect(() => {
    if (!loggedIn) return;

    getRequest({
      endpoint: 'networks',
    })
      .then(networks => dispatch(setNetworks(networks)))
      .catch(() => {});
  }, [dispatch, loggedIn]);

  useEffect(() => {
    const uuid = localStorage.getItem('gig_social_uuid');

    if (!uuid && !loggedIn) return;

    getRequest({
      endpoint: loggedIn ? 'carts/current' : `carts/${uuid}/draft`,
    })
      .then(response => {
        dispatch(setCart(response));
      })
      .catch(() => {});
  }, [dispatch, loggedIn]);

  useEffect(() => {
    if (!loggedIn) return;

    getRequest({ endpoint: 'notifications/unseen_count' })
      .then(count => dispatch(setUnseenNotificationsCount(count)))
      .catch(() => {});

    getRequest({ endpoint: 'profiles/following_ids' })
      .then(ids => dispatch(setFollowingIds(ids)))
      .catch(() => {});
  }, [dispatch, currentUser.profile_id, loggedIn]);

  useEffect(() => {
    if (!loggedIn || webNotifications) return;

    try {
      if (window.Notification.permission === 'granted' && !webNotifications) {
        dispatch(setWebNotifications(true));
        return;
      }

      window.Notification.requestPermission().then(response => {
        if (response === 'granted') dispatch(setWebNotifications(true));
      });
    } catch (e) {
      console.log(e);
    }
  }, [loggedIn, webNotifications, dispatch]);

  useEffect(() => {
    document.addEventListener('keyup', keyUpHandler);

    return () => document.removeEventListener('keyup', keyUpHandler);
  }, [keyUpHandler]);

  return children;
};

export default Root;
